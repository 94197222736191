// GLOBAL

*::-webkit-scrollbar{
  display: none;
}

*{
  scrollbar-width: none;
}

html.is-changing .transition-fade {
  transition: opacity 0.25s;
  opacity: 1;
}
/* Define the styles for the unloaded pages */
html.is-animating .transition-fade {
  opacity: 0;
}

html,body{
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

main{
  height: 100%;
  width: 100%;
  opacity: 1;
  padding: 12.76923076923077em 0 4em;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: opacity 1s 1s 1s;
  @include mq(md){
    padding: 12.76923076923077em 0 7em;
  }
  &.no-overflow{
    overflow: hidden;
  }
  &.is-loaded{
    opacity: 1 !important;
    transition: opacity $transition-timing-default $transition-duration-default 0.5s;
  }

}

#home nav{
  display: none;
}

#home .site-header{
  display: none;
}

#home main{
  padding: 0;
}

.hide{
  display: none !important;
}

.show{
  display: grid;
}





// HEADER

#home header{
  background: none;
}

#home nav{
  mix-blend-mode: difference;
  li{
    color: white;
    a{
      color: white;
      &:hover{
        color: grey;
      }
    }
  }
}

#home .site-header{
  mix-blend-mode: difference;
  background: transparent;
  a{
    color: white;
    &:hover{
      color: grey
    }
  }
}

/*.site-header{
  mix-blend-mode: difference;
  background: transparent;
  a{
    color: var(--color-link);
  }
}*/

header{
  width: 100%;
  text-align: center;
  padding: 1.5em;
  position: fixed;
  background: white;
  z-index: 9999999;
  top:0;
  @include mq(md){
    padding: 3em;
  }
}

header a{
  color: black;
}

#home nav{
  background: none;
}

nav{
  width: 100%;
  position: fixed;
  bottom:0;
  padding: 1.5em;
  display: flex;
  background: white;
  z-index: 99999;
  justify-content: center;
  li{
    list-style-type: none;
    margin: 0 1em;
  }
  @include mq(md){
    padding: 3em;
  }
}


// HOME

.home-link a{
  position: fixed;
  z-index: 9999;
  font-size: 20px;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(md){
    font-size: 25px;
  }
}

.home-link img{
  height: 35px;
  @include mq(md){
    height: 56px;
  }
}

.background-image{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}
.background-image picture{
  position: absolute;
  width: 100%;
  height: 100%;
}

.background-image picture img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.background-image video{
  height: 100%;
  width: 100%;
  object-fit: cover;
}



// DEFAULT PAGE

.default-page-content{
  width: 100%;
  min-height: 100%;
  padding: 0 1.5em;
  display: grid;
  grid-template-columns: 50% 50%;
  .cover-container{
    display: none;
  }
  .content{
    grid-column: span 2;
  }

  @include mq(md){
    padding: 0 3em;
    grid-template-columns: calc(50% - 12em) 50%;
    .cover-container{
      display: flex;
    }
    .content{
      grid-column: auto;
    }

  }
}

.default-page-content .entry{
  display: grid;
  grid-template-areas: "title content";
  padding-bottom: 1em;
  /*grid-auto-columns: 8em 1fr;*/
  grid-template-columns: 1fr 1fr;
  width: 100%;
  @include mq(md){
    grid-template-columns: 12em 1fr;
    width: 100%;
  }
  .entry-title{
    text-align: right;
    margin-right: 0.65em;
  }
  .entry-content{
    text-align: left;
    margin-left: 0.65em;
  }
  max-width: 75em;
}

#contact .default-page-content .entry{
  max-width: 70em;
}

.default-page-content .entry p{
  margin-bottom: 1em;
}


// NEW PROJECTS PAGE
.grid{
  width: 100%;
  padding-bottom: 3em;
}
.gutter-sizer{
  width: 0;
  @include mq(md){
  width: 5%;
  }
}
.grid-sizer{
  width: 100%;
  @include mq(md){
    width: 30%;
  }
}
.grid-item{
  float: left;
  width: 100%;
  height: auto;
  /*margin-bottom: 100px;*/
  @include mq(md){
    width: 30%;
    margin-bottom: 0;
  }
}

.grid-item--width2 { width: 120px; }
.grid-item--height2 { height: 120px; }

.project-grid{
  margin: 0 5%;
  width: calc(100% - 10%);
}

.project-grid-item{
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  @include mq(md){
    margin-bottom: 0;
  }
  video{
    width: 100%;
  }
  img.landscape{
    width: 100%;
  }
  img.portait{
    height: 100%;
  }
  img.square{
    width: 80%;
  }
  .legend{
    text-align: center;
    opacity: 1;
    z-index: 999;
    pointer-events: auto;
    margin-top: 1em;
    transition: none;
    width: 100%;
    text-align: center;
    color: black;
    @include mq(lg){
      position: absolute !important;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  a:hover .legend{
    opacity: 1;
    transition: opacity 0.2s;
    pointer-events: auto;
  }
}

.project-pagination{
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: space-between;
  @include mq(md){
    position: relative;
    top: -2.5em;
  }
  @include mq(lg){
    padding: 0 8em;
  }
}

// ARCHIVES PAGE

#index.projects-page-content{
  display: grid;
  grid-template-columns: 50% 50%;
  .cover-container{
    display: none;
  }
  @include mq(md){
    grid-template-columns: calc(50% - 12em) 1fr 1fr;
    .cover-container{
      display: flex;
    }
  }
}

.projects-page-content{
  @extend .default-page-content;
  justify-content: center;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  position: relative;
 display: flex;
  align-items: start;
}

.cover-container{
  height: calc(100vh - 12.7692307692em - 7em);
  position: sticky;
  top:0;
  overflow: scroll;
  max-width: 38vw;
  display: flex;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  padding: 0 5vw 0 10vw;
  @include mq(lg){
   opacity: 1;
  }
  picture, img{
    display: block;
    height: 100%;
    width: 100%;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.no-overflow .projects-page-content{
  height: 100%;
  min-height: auto;
}

.projects-menu{
  display: flex;
  position: fixed;
  left: 0;
  padding-bottom: 2em;
  margin-top: -1px;
  justify-content: center;
  width: 100%;
  grid-column: span 3;
  background: white;
}

.projects-menu li{
  margin: 0 0.65em;
}

.projects-container{
  grid-column: span 2;
  @include mq(md){
    grid-column: auto;
  }
}

.projects-container{
  align-items: flex-start;
  justify-content: center;
  padding-top: 3em;
}

.projects p{
  color: var(--color-link) !important;
  cursor: pointer;
  span{
    display: inline;
    margin-left: 0.5em;
  }
  @include mq(md){
    span{
      display: none;
    }
  }
  &:hover{
    color: black !important;
    span{
      display: inline;
      margin-left: 0.5em;
    }
  }
}

#chronologie{
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: 50% max-content;
  .year{
    margin-right: 0.65em;
    text-align: right;
  }
  .projects{
    margin-left: 0.65em;
  }
  .projects + .year{
    margin-bottom: 1em;
  }
  .projects p:last-of-type{
    margin-bottom: 1em;
  }
  @include mq(md){
    grid-auto-columns: 12em max-content;
  }
}

#type{
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: 50% max-content;
  width: 100%;
  .type{
    margin-right: 0.65em;
    text-align: right;
  }
  .projects{
    margin-left: 0.65em;
  }

  .projects + .type{
    margin-bottom: 1em;
  }
  .projects p:last-of-type{
    margin-bottom: 1em;
  }
  @include mq(md){
    grid-auto-columns: 12em max-content;
  }
}

#echelle{
  display: grid;
  grid-template-areas: "a a";
  grid-auto-columns: 50% 50%;
  .scale{
    margin-right: 0.65em;
    text-align: right;
  }
  .projects{
    margin-left: 0.65em;
  }
  .projects + .scale{
    margin-bottom: 1em;
  }
  .projects p:last-of-type{
    margin-bottom: 1em;
  }
  @include mq(md){
    grid-auto-columns: 12em max-content;
  }
}