// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    sm: (
      fs: 11px,
      lh: 12px,
    ),
    md: (
      fs: 11px,
      lh: 12px,
    ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  --base-space: 8px;
  --color-text: black;
  --color-link: grey;
  --color-bg: white;
  --color-hover: black;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: Helvetica,
    fallback: "Helvetica, Lucida Grande, sans-serif",
    withFile: true,
  ),
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  sm: 0px,
  md: 640px,
  lg: 1024px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
